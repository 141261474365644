.Wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .title {
    margin-top: 23px;
    color: #001847;
    font-size: 15px;
    font-weight: 400;
  }
  .subTitle {
    color: #8387a5;
    font-size: 13px;
  }
}

.LoaderWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding-top: 15%;
}

.Circle {
  stroke: var(--color-white);
}

.Dialog {
  position: fixed;
  bottom: 0;
  right: 11px;
  width: 354px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  z-index: 5005;
  max-height: 100vh;

  * {
    cursor: default !important;
  }
}

.Accordion {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.DialogHeader {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-color: var(--primary-text-color);
  height: 54px;
  color: var(--color-white);
}

.IconsWrapper {
  display: flex;
  align-items: center;
}

.ExpandIcon {
  width: 15px;
  height: 15px;
  position: relative;
  margin-right: 35px;
  cursor: pointer !important;

  &:before {
    content: '';
    position: absolute;
    top: 5px;
    left: 0;
    width: 13px;
    height: 13px;
    border: 1px solid var(--color-white);
    transform: rotate(45deg);
    border-right: none;
    border-bottom: none;
    transition: transform 100ms;
  }

  &.Expanded {
    &:before {
      top: -3px;
      transform: rotate(225deg);
    }
  }
}

.CloseIcon {
  width: 15px;
  height: 15px;
  fill: var(--color-white);
  cursor: pointer !important;
}

.Summary {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Body {
  padding: 0;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  max-height: calc(100vh - 64px);
}

.TextWrapper {
  background-color: var(--color-white);
  height: calc(100% - 129px);
  overflow-y: scroll;
  padding: 15px;
  width: 70%;
  margin: 0 auto;
}

.Text {
  span {
    word-wrap: break-word;
  }
}

.selected:global(.MuiInputBase-root) {
  cursor: default;
}

.selected:global(.MuiInputBase-root) {
  cursor: default;
}

.searchInputHidden {
  caret-color: transparent;
  cursor: default;
}

.option {
  display: flex;
  align-items: center;
  max-width: 100%;
}

.optionAvatar {
  margin-right: 10px;
}

.optionText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

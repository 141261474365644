.Wrapper {
  margin-left: 40px;
  position: relative;
}

.SearchInput {
  max-width: 170px;
  width: 100%;
  height: 32px;
  border-radius: 4px;
  border: 1px solid var(--primary-border-color);
  background-color: var(--color-white);
  transition: width 100ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  will-change: width;
  z-index: 1200;

  &.Active {
    border: solid 1px #5f48e9;
    width: 315px;

    input {
      &::placeholder {
        color: #d8dfea !important;
      }
    }

    svg {
      fill: #d8dfea !important;
    }
  }

  svg {
    margin-left: 7px;
    margin-top: 4px;
    fill: #8387a5;
  }

  &:global(.MuiInputAdornment-root) {
    padding-right: 20px;
  }

  input {
    font-size: 13px;
    display: flex;
    align-items: center;

    &::placeholder {
      color: #8387a5;
      opacity: 1;
    }
  }
}

.SearchWindow {
  z-index: 9;
  width: 770px !important;
  height: 500px !important;
  box-shadow: 10px 10px 40px 0 #d8dfea !important;
  background: #fff !important;
  border-radius: 10px !important;
  padding: 20px !important;
  overflow: hidden !important;
  display: flex !important;

  @media (max-height: 700px) {
    height: 400px !important;
  }
  @media (min-height: 700px) {
    height: 600px !important;
  }

  @media (min-height: 900px) {
    height: 746px !important;
  }
}

.ResultsSection {
  width: 480px;
  max-height: 746px;
  overflow-y: scroll;
  padding-right: 10px;
}

.FiltersSection {
  width: 270px;
  margin-left: 20px;
  border-left: solid 1px #d1d9e6;
  padding-left: 20px;
  overflow: auto;
}

.ClearButton.ClearButton {
  all: unset;
  cursor: pointer;
  margin-right: 10px;

  svg {
    fill: var(--color-black) !important;
    height: 12px !important;
    width: 12px !important;
  }
}

.OpenButton {
  all: unset;
  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;
  }
}

.Wrapper {
  margin-top: 20px;
}

.List {
  margin-top: 15px;

  &.Expanded {
    max-height: unset;
    overflow: unset;
  }
}

.Item {
  all: unset;
  width: 100%;
  display: flex;
  margin-top: 15px;
  cursor: pointer;

  &:first-child {
    margin-top: 0;
  }
}

.IconWrapper {
  margin-right: 10px;
}

.TaskIcon {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #5f48e9;
  border-radius: 50%;
}

.Title {
  font-size: 13px;
  color: #001847;
  margin-right: 10px;

  span {
    font-weight: bold;
  }
}

.Avatar {
  width: 20px !important;
  height: 20px !important;
  font-size: 12px !important;

  img {
    width: 20px;
    height: 20px;
  }
}

.ContactInfo {
  display: flex;
  align-items: center;
  margin-top: 9px;

  span {
    margin-left: 5px;
    font-size: 11px;
    color: #8387a5;

    span {
      font-weight: bold;
      color: #001847;
    }
  }
}

.TaskWrapper {
  display: flex;
  align-items: center;

  svg:hover {
    opacity: 1 !important;
  }

  svg {
    fill: #7967ec;
  }
}

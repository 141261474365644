.chip {
  color: var(--primary-text-color);
  background-color: rgba(131, 135, 165, 0.1);
  padding: 4px 8px 4px 4px;
  font-size: 11px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  cursor: default;
  border: 0;
  position: absolute;
  max-width: calc(100% - 32px);
}

.Avatar {
  margin-right: 5px;
}

.text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Menu {
  margin-top: 5px;
}

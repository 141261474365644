.wrapper {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.errorMessage {
  color: #fa0043;
  margin-right: 20px;
  font-size: 11px;
}

.discountSelect {
  display: flex;
  border: 1px solid var(--primary-border-blur-color);
  border-radius: 6px;

  &.selectErrorState {
    border-color: #fa0043;
  }

  &:focus-within {
    border-color: var(--primary-text-link-color);
  }

  .discountTypeSelect {
    margin: 0;
    border: none;
    width: 80px;
    //border-right: 1px solid var(--primary-border-blur-color);
    border-radius: 0;
    padding-right: 4px;
  }

  .discountValueInput {
    margin: 0;
    border: none;
    width: 140px;
    position: relative;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 2px;
      left: 4px;
      width: 1px;
      height: calc(100% - 4px);
      background-color: #bfc7d6;
    }
  }
}

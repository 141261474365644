@import 'src/styles/media';

html,
body {
  padding: 0;
  margin: 0;
  font-family: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

#__next {
  display: flex;
  flex-direction: column;
  height: 100vh;

  @include media-sm {
    min-height: 100vh;
    height: auto;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
svg path {
  transition: 0.2s;
}
button {
  cursor: pointer;
}

.leader-search-divider {
  width: 100%;
  height: 1px;
  background: #d1d9e6;
  opacity: 0.5;
  margin-top: 12px;
}

.leader-search-see-more {
  all: unset;
  font-size: 13px;
  color: #5f48e9;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 15px 0;
}

.leader-search-see-more svg {
  fill: #5f48e9;
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.2);
}

.leader-droppable-column {
  height: 100%;
  position: relative;
  padding-left: 8px;
  padding-right: 8px;
  min-height: 1px;
}

.leader-droppable-column div {
  position: relative;
}

.leader-droppable-column * {
  z-index: 1;
}

.leader-droppable-column::before {
  content: '';
  width: var(--mask-width);
  height: var(--mask-height);
  position: absolute;
  top: -61px;
  left: 0;
  border-radius: 10px;
  z-index: 0;
}

.leader-common-dialog {
  border-radius: 8px;
  box-shadow: 10px 10px 40px 0 var(--color-mystic);
}

.leader-no-scrollbar {
  ::-webkit-scrollbar {
    background-color: transparent;
  }
}

.container {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.month {
  color: var(--primary-text-color);
  font-size: 17px;
  padding-right: 5px;
  font-weight: 600;
  text-transform: capitalize;
}
.yearInput {
  background-color: #eff3f7;
  border: none;
  max-width: 60px;
  padding: 0 10px;
  height: 30px;
  font-weight: 600;
  min-height: 30px;
}
.yearInput input {
  text-align: center;
}
.yearInput input::-webkit-outer-spin-button,
.yearInput input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.yearInput input[type='number'] {
  -moz-appearance: textfield;
}

@import 'src/styles/media';

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  background: var(--color-white);
  z-index: 100000;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  hr {
    max-width: 575px;
    width: 100%;
    height: 1px;
    background-color: var(--primary-border-color);
    border: none;
    margin: 30px 0;

    @include media-sm {
      display: none;
    }
  }
}

.Title,
.Subtitle {
  font-family: var(--font-poppins);
  text-align: center;
}

.Title {
  color: var(--primary-text-color);
  font-size: 32px;
  font-weight: bold;
}

.Subtitle {
  font-size: 16px;
  color: var(--secondary-text-color);
  margin-top: 6px;
}

.ProgressBar {
  background: var(--primary-border-color);
  width: 316px;
  height: 6px;
  border-radius: 20px;
  margin-top: 25px;
  position: relative;

  div {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 20px;
    height: 6px;
    background: var(--primary-button-color);
    transform-origin: left;
    width: 9%;
    animation: loading 10000ms cubic-bezier(0.4, 0, 0.6, 1) forwards;
  }
}

@keyframes loading {
  0% {
    width: 9%;
  }
  13% {
    width: 12.5%;
  }
  25% {
    width: 25%;
  }
  37% {
    width: 37%;
  }
  50% {
    width: 50%;
  }
  67% {
    width: 67%;
  }
  75% {
    width: 75%;
  }
  88% {
    width: 88%;
  }
  100% {
    width: 100%;
  }
}

.TipBoard {
  display: flex;
  height: 69px;

  @include media-sm {
    display: none;
  }

  div {
    &:first-child {
      svg {
        margin: 5px 17px 0 0;
      }
    }

    &:last-child {
      max-width: 386px;
      font-size: 15px;
      text-align-last: center;
      line-height: 23px;
      font-family: var(--font-poppins);
      color: var(--primary-text-color);
      font-weight: 300;
    }
  }
}

.Preload {
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  width: 0;
}

.ImageWrapper {
  height: 600px;
  position: relative;
  width: 795px;

  @include media-sm {
    display: none;
  }

  & > span {
    position: absolute !important;
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    &:first-child {
      width: 795px !important;
      height: 520px !important;
    }

    &:nth-child(2) {
      width: 664px !important;
      height: 492px !important;
    }

    &:nth-child(3) {
      width: 563px !important;
      height: 518px !important;
    }

    &:nth-child(4) {
      width: 521px !important;
      height: 596px !important;
    }
  }
}

.Wrapper {
  margin-top: 20px;
}

.List {
  margin-top: 15px;

  &.Expanded {
    overflow: unset;
    max-height: unset;
  }
}

.Avatar {
  width: 20px !important;
  height: 20px !important;
  margin-left: 40px;
  font-size: 12px !important;

  img {
    width: 20px;
    height: 20px;
  }
}

.ContactInfo {
  display: flex;
  align-items: center;
  margin-top: 5px;

  span {
    font-size: 11px;
    color: #8387a5;
    margin-left: 6px;
    display: block;

    span {
      display: inline;
      font-weight: bold;
      color: #001847;
    }
  }
}

.ItemWrapper {
  all: unset;
  width: 100%;
  margin-top: 10px;
  cursor: pointer;

  &:first-child {
    margin-top: 0;
  }
}

.Name {
  max-height: 40px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.popper {
  z-index: 2;
}

.pickerWrapper {
  padding: 15px 25px;
}

.saveButton {
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 15px;
}

.callsWrapper {
  display: flex;
  width: 100%;
  overflow: hidden;
}

.errorState {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 120px;
}

.errorStateContent {
  max-width: 360px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.errorStateIcon {
  margin-bottom: 8px;
}

.errorStateIcon svg {
  font-size: 48px;
}

.errorStateText {
  font-size: 18px;
  font-weight: 300;
}

.errorStateActions {
  text-align: center;
  margin-top: 16px;
}

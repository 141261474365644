.Select {
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
}

.ArrowIcon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

label + .Select:global(.MuiInput-formControl) {
  margin-top: 0;
}

.Select::before,
.Select::after {
  display: none;
}

.Select.outlined {
  border-color: var(--primary-border-blur-color);
  padding-left: 12px;
}

.Select.outlined:global(.Mui-focused) {
  border-color: var(--primary-text-link-color);
}

.Select.standard {
  border-color: transparent;
}

.Select.standard :global(.MuiSelect-root) {
  padding-left: 0;
}

.Select :global(.MuiSelect-select.MuiSelect-select:focus) {
  background-color: transparent;
}

.Select :global(.MuiSelect-selectMenu) {
  min-height: auto;
  font-size: 15px;
}

.Select fieldset {
  display: none;
}

.SelectMenu :global(.MuiListItem-button):hover {
  background-color: #efecfd !important;
}

.SelectMenu :global(.MuiListItem-root.Mui-selected),
.SelectMenu :global(.MuiListItem-root.Mui-selected):hover,
.SelectMenu :global(.Mui-focusVisible) {
  background-color: #efecfd !important;
  color: #5f48e9;
}

.Popper {
  z-index: 9000;
  margin-top: 6px;
}

.BaseInput {
  text-transform: capitalize;
}

.optionsWrapper {
  width: 100%;
  padding: 14px 20px;
  border-radius: 6px;
  box-shadow: 4px 4px 20px 0 #d8dfea;
  background-color: #fff;
}

.FormControlLabel {
  width: 100%;
}

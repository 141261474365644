.button {
  position: relative;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  border: 0;
  cursor: pointer;
  margin: 0;
  user-select: none;
  border-radius: 0;
  -moz-appearance: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  padding: 3px 5px;
  background-color: #f2f3f6;
  border-radius: 3px;
  transition: 0.25s background-color;
}

.button:disabled {
  cursor: default;
}

.button:disabled:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background-color: rgba(255, 255, 255, 0.25);
}

.button:not(:disabled):hover {
  background-color: #e6e7f6;
}

.buttonAvatar {
  margin-right: 6px;
}

.buttonText {
  font-size: 9px;
  font-weight: 400;
  color: var(--primary-text-color);
  margin-right: 5px;
  max-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.buttonIconArrow {
  width: 9px;
  fill: var(--primary-text-color);
}

.buttonIconArrow.menuOpen {
  transform: scale(1, -1);
}

.option {
  padding: 7px 12px;
  font-size: 12px;
  font-weight: 400;
  color: var(--primary-text-color);
}

.optionAvatar {
  border: 1px solid #f5f6f8;
  margin-right: 7px;
}

.optionAddTeammatesIcon {
  background-color: #5f48e9;
  color: #fff;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 7px;
}

.ImageContainer {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
}

.Image {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  object-fit: cover;
  visibility: hidden;

  &.Visible {
    visibility: visible;
  }
}

.Wrapper {
  margin-top: 30px;
}

.List {
  margin-top: 8px;
}

.Item {
  all: unset;
}

.Item,
.ContactInfo {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;

  * {
    user-select: none;
  }
}

.ContactInfo {
  span {
    font-size: 13px;
    color: #001847;
    margin-left: 10px;
  }
}

.Avatar {
  width: 30px !important;
  height: 30px !important;

  img {
    width: 30px !important;
    height: 30px !important;
  }
}

.Dialog {
  box-shadow: 10px 10px 40px 0 #d8dfea !important;
  border-radius: 12px !important;
  padding: 11px 8px !important;
  background: white !important;
  width: 230px !important;
}

.Input {
  width: 100%;
  background: rgba(117, 114, 149, 0.07);
  height: 36px;
  max-height: 36px;
  min-height: 36px !important;
  font-size: 13px !important;
}

.Divider {
  position: relative;
  width: calc(100% + 16px);
  left: -8px;
  height: 1px;
  background-color: #eff3f7;
  margin-top: 10px;
}

.DialogItem {
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 100%;
}

.wrapper {
  display: inline-flex;
  align-items: center;
  font-size: 13px;
  font-weight: 400;
  color: #727f8a;
}

.avatar {
  margin-right: 10px;
}

.Title {
  text-transform: uppercase;
  font-weight: 200;
  color: #001847;
  font-size: 13px;
}

.Wrapper {
  margin-top: 20px;
}

.ClearButton {
  color: var(--primary-button-color);
  font-family: var(--font-poppins);
  font-size: 13px;
  margin-top: 30px;
  cursor: pointer;
}

.container {
  width: 390px;
  border-radius: 20px;
  box-shadow: 10px 10px 40px 0 rgba(166, 158, 204, 0.1);
  text-align: center;
}

.actions {
  justify-content: center;
}
.title {
  padding-top: 25px;
  padding-bottom: 0;
}
.title h2 {
  font-size: 19px !important;
  font-weight: 500 !important;
  color: #001847;
  padding-bottom: 5px;
}

.description {
  color: var(--primary-text-color);
  font-size: 17px;
  text-align: center;
  font-weight: 300;
}

.button {
  text-transform: capitalize;
  font-size: 17px;
  font-weight: 400;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.cancelButton {
  color: #8387a5 !important;
}

.divider {
  height: 28px;
  margin: 0 20px !important;
}

.userPicButton {
  margin: 0;
  border: 0;
  padding: 0;
  flex-shrink: 0;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  -moz-appearance: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;

  .picture {
    border-radius: 50%;
  }
  .pictureAlt {
    color: #fff;
    background-color: #001847;
    border-radius: 50%;
    font-size: 20px;
    width: 36px;
    height: 36px;
    align-items: center;
    justify-content: center;
    display: flex;
    text-transform: uppercase;
  }
}
.userPicButtonRedDot {
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: -4px;
    right: -4px;
    border: 2px solid #fff;
    background-color: #ff336a;
    border-radius: 50%;
    width: 10px;
    height: 10px;
  }
}

.wrapper {
  border-radius: 5px;
  height: 20px;
  line-height: 20px;
  padding: 0 12px 0 8px;
  display: inline-block;
  align-items: center;
  background-color: #fef2e2;
  color: #f6a304;
  fill: #f6a304;
  font-size: 11px;
  font-weight: 500;
}

.wrapper.completed {
  background-color: #d0f8de;
  color: #19e25e;
  fill: #19e25e;
}

.wrapper.overdue {
  background-color: #fed5e0;
  color: var(--primary-text-error-color);
  fill: var(--primary-text-error-color);
}

.icon {
  height: 9px;
  width: 9px;
  margin-right: 6px;
  fill: inherit;
  opacity: 0.5;
}

.wrapper span {
  text-transform: capitalize;
}

.Button {
  all: unset;
  cursor: pointer;
  margin-left: 30px;
  height: 22px;
}

.Popover {
  overflow: visible;

  &:before {
    content: '';
    top: -7px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    width: 17px;
    height: 17px;
    background-color: var(--color-white);
    position: absolute;
    z-index: 0;
    border-radius: 3px;
  }

  a {
    display: block;
    padding: 10px 15px;
    transition: 0.25s background-color;
    font-size: 11px;
    font-family: var(--font-poppins-regular);
    color: var(--primary-text-color);
    position: relative;
    z-index: 1;

    &:hover {
      background-color: var(--color-titan-white);
    }
  }
}

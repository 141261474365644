.container {
  display: flex;
  flex-flow: column;
  height: 100%;
  align-items: center;
  justify-content: center;
  .title {
    color: #fa0043;
    font-size: 19px;
    font-weight: 500;
    margin-top: 25px;
    margin-bottom: 15px;
  }
  .description {
    max-width: 250px;
    width: 100%;
    font-weight: 17px;
    color: #000000;
    margin: 0;
    text-align: center;
  }
  .actionsContainer {
    display: flex;
    margin-top: 40px;
    align-items: center;
    .viewExampleButton {
      color: #8387a5 !important;
      font-weight: 400;
      font-size: 17px;
      margin-right: 40px;
    }
    .buttonCancel {
      color: #8387a5 !important;
      font-weight: 400;
      font-size: 17px;
      margin-right: 40px;
    }
    .buttonTryAgain {
      font-weight: 400;
      padding: 5px 25px;
      font-size: 17px;
      height: 35px;
      width: 130px;
    }
  }
}

.wrapper {
  padding: 30px 15px;
  position: relative;
  .spinnerWrapper {
    width: 400px;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .rowContainer {
    position: relative;
    padding: 10px 15px;
    display: flex;
    margin-top: 25px;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s;
    border-radius: 6px;

    &:hover {
      background-color: #f2f2ff;
    }

    .notificationLink {
      color: #5f48e9;
    }
    .notificationIcon {
      &.edit {
        svg {
          margin-left: 4px;
          fill: #8387a5;
          width: 15px;
        }
      }
      &.bell {
        svg {
          transform: scale(0.7);
          fill: #8387a5;
          path {
            fill: #8387a5;
          }
        }
      }
      &.delete {
        svg {
          fill: #8387a5;
          width: 15px;
        }
      }
      &.contact {
        svg {
          fill: #8387a5;
          margin: 0 -8px;
          width: 30px;
        }
      }
    }
    .notificationContainer {
      margin-left: 15px;
      display: flex;
      flex-flow: column;
      .notificationTitle {
        font-size: 13px;
        color: #001847;
        font-weight: 500;
      }
      .notificationDate {
        color: #8387a5;
        font-size: 11px;
      }
    }
    &.unreadRow {
      &:before {
        position: absolute;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        content: '';
        top: 50%;
        left: 5px;
        transform: translate(0, -50%);
        background-color: #ff336a;
      }
    }
  }
}

.titleContainer {
  top: -30px;
  padding-top: 30px;
  margin-top: -30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  z-index: 2;
  background-color: #fff;
  .clearAll {
    background-color: transparent;
    color: #001847;
    font-size: 11px;
    border: none;
    outline: none;
    transition: 0.3s;
    &:hover {
      color: #5f48e9;
    }
  }

  .title {
    font-size: 14px;
    margin: 0;
    color: #001847;
    text-transform: uppercase;
    font-weight: 400;
  }
}

.emptyWrapper {
  width: 400px;
  height: 500px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  .emptyIcon {
    position: relative;
    width: 60px;
    height: 60px;
    border: 3px solid #000;
    border-radius: 23px;
    &::before {
      z-index: 1;
      content: ' ';
      width: 35px;
      height: 35px;
      position: absolute;
      right: -10px;
      top: -10px;
      background-color: #fff;
    }
    &::after {
      z-index: 2;
      content: ' ';
      width: 30px;
      height: 30px;
      background-color: #ff3b30;
      border-radius: 50%;
      right: -10px;
      top: -10px;
      position: absolute;
    }
  }
  .emptyTitle {
    color: #8b90a3;
    font-size: 19px;
    margin-top: 20px;
  }
}

.Wrapper {
  margin-top: 20px;
}

.List {
  margin-top: 15px;
  max-height: 120px;
  overflow: hidden;

  &.Expanded {
    overflow: unset;
    max-height: unset;
  }
}

.Item {
  all: unset;
  margin-top: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;

  &:first-child {
    margin-top: 0;
  }
}

.Icon {
  background: #000576;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.TitleWrapper {
  margin-left: 10px;
}

.Title {
  color: #001847;
  font-size: 13px;

  span {
    font-weight: bold;
  }
}

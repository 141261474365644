@import 'src/styles/media';

.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(256, 256, 256, 0.9);
  z-index: 1200;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 20px calc(100% / 2 - 245px);
  text-align: center;

  .innerWrapper {
    padding: 0 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    h2 {
      margin: 0 0 15px;
      font-size: 22px;
      color: var(--primary-text-color);
    }

    p {
      font-size: 16px;
      margin: 0;
      line-height: 28px;
      color: var(--primary-text-color);

      a {
        color: var(--primary-text-link-color);

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .buttonsWrapper {
      display: flex;
      align-self: center;
      flex-wrap: wrap;
      justify-content: center;

      button:not(:last-child) {
        margin-right: 20px;
      }
    }

    button {
      margin-top: 20px;

      @include media-sm {
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    > a {
      margin-top: 20px;
      color: var(--primary-text-color);
      display: flex;
      align-items: center;
      font-size: 16px;

      svg {
        fill: var(--primary-text-color);
        margin-right: 10px;
      }

      &:hover {
        color: var(--primary-text-link-color);

        svg {
          fill: var(--primary-text-link-color);
        }
      }
    }
  }
}

.Menu :global(.MuiMenu-paper) {
  box-shadow: 10px 10px 40px 0 #d8dfea !important;
}

.MenuItem:global(.MuiListItem-button):hover {
  background-color: #efecfd !important;
}

.MenuItem:global(.MuiListItem-root.Mui-selected),
.MenuItem:global(.MuiListItem-root.Mui-selected):hover,
.MenuItem:global(.Mui-focusVisible) {
  background-color: #efecfd !important;
  color: #5f48e9;
}

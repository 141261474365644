.dropzone {
  position: relative;
  width: 108px;
  height: 108px;
  padding: 18px;
  border-style: dashed;
  border-width: 1px;
  border-color: #d8dfea;
  background-color: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  transition: background-color 0.25s, border-color 0.25s;
}

.dropzone.active {
  border-color: #5f48e9;
  background-color: #efecfd;
}

.dropzone.active .imagePlaceholder {
  color: #fff;
  fill: #fff;
}

.dropzone.loading {
  cursor: default;
}

.dropzone.active .hint {
  opacity: 0;
}

.iconWrapper {
  width: 44px;
  height: 52px;
  text-align: center;
}

.imagePlaceholder {
  width: 40px;
  height: 100%;
  color: #efecfd;
  fill: #efecfd;
  flex-shrink: 0;
  transition: fill 0.25s, color 0.25s;
}

.warning {
  color: var(--primary-text-error-color);
  fill: var(--primary-text-error-color);
}

.hint {
  font-size: 11px;
  color: var(--secondary-text-color);
  margin-top: 4px;
  transition: opacity 0.25s;
}

.attachment {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  position: absolute;
  bottom: -12px;
  right: -12px;
}

.attachment svg {
  width: 8px;
  height: 15px;
  color: var(--primary-button-color);
  fill: var(--primary-button-color);
}

.helpers {
  margin-top: 8px;
  min-height: 24px;
  max-width: 108px;
  position: relative;
}

.removeImage {
  font-size: 11px;
  color: var(--primary-button-color);
  font-family: inherit;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  vertical-align: text-top;
}

.spinnerWrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.Spinner {
  display: block;
}

.error {
  margin-top: 0;
}

.Wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  span {
    margin-top: 23px;
    color: #5f48e9;
    font-size: 15px;
    font-weight: 400;
  }
}

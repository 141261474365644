.wrapper {
  background-color: var(--primary-border-color);
  color: var(--primary-text-color);
  padding: 16px 20px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;

  > span {
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 20px;
    width: 100%;
    display: block;

    &:not(.isMember) {
      row-gap: 10px;
      display: flex;
    }

    > span {
      flex: 1;
      min-width: 80px;
    }
  }

  button {
    white-space: nowrap;
    border-radius: 5px;
    font-weight: 400;
    padding: 7px 20px;
  }
}

.wrapper {
  padding: 30px 0 0;
  width: 560px;
}

.row {
  width: 100%;
}

.styles.wrapper {
  display: flex;
}
.isStickyButtonContainer {
  background-color: #000;
}
.saveButtonContainer {
  position: sticky;
  bottom: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  height: 70px;
}
.saveButtonContainer::after {
  content: '';
  width: calc(100% + 72px);
  top: 0;
  height: 100%;
  left: -36px;
  z-index: 0;
  position: absolute;
  background-color: #fff;
  box-shadow: 0 -3px 10px 0 rgba(0, 0, 0, 0.1);
}
.saveButtonPlace {
  position: relative;
  margin-left: 40px;
  z-index: 1;
  padding-bottom: 30px;
}
.saveButtonContainer .saveButtonPlace {
  padding-bottom: 0;
}
.spinnerPlace {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.spinner {
  display: block;
}

@import '../../styles/media';

.wrapper {
  display: flex;
  background-color: #f6f8fb;
  overflow-y: auto;
  flex: 1;
  position: relative;

  @include media-sm {
    height: auto;
  }

  &.WithSidebar {
    width: calc(100% - var(--sidebar-width));
    margin-left: var(--sidebar-width);
  }

  &.WithOpenedSidebar {
    width: calc(100% - var(--sidebar-width-expanded));
    margin-left: var(--sidebar-width-expanded);
  }

  &.WithOpenedSidebar,
  &.WithSidebar {
    // left sidebar isn't shown on mobile web
    @include media-sm {
      width: 100%;
      margin-left: 0;
    }
  }
}

.header {
  z-index: 2;
  position: relative;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.02);
  height: var(--header-height);
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  max-width: 100%;
}
.headerRight {
  margin-left: auto;
}
.headerContainer {
  display: flex;
  height: 100%;
  align-items: center;
}
.headerBurger:hover svg path:not([fill='none']) {
  fill: #5f48e9;
}
.headerBurger {
  width: auto;
  padding: 0;
  background: none;
  border: none;
  display: flex;
  margin-right: 105px;
  cursor: pointer;
}
.headerSearchInput {
  width: 400px;
  height: 48px;
  border-radius: 10px;
  background-color: #f5f5f8;
  margin-left: 220px;
}
.headerSearchInput svg {
  font-size: 30px;
}
.headerSearchInput :global(.MuiInputAdornment-root) {
  padding-right: 20px;
}
.headerSearchInput input {
  padding-left: 25px;
  font-size: 16px;
}
.headerSearchInput input::placeholder {
  color: #8387a5;
  opacity: 1;
}
.sidebar {
  width: 240px;
  position: relative;
  padding-top: 40px;
  padding-left: 0;
  padding-right: 0;
  height: auto;
  min-height: calc(100vh - var(--header-height));
  background-color: #fff;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
}
.sidebar .linkText {
  font-size: 13px;
  text-transform: uppercase;
  color: #8387a5;
  margin-left: 25px;
}
.sidebar .linkActive .linkText {
  color: #5f48e9;
}
.sidebar .linkIcon {
  width: 96px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contactIcon {
  width: 48px;
  height: 48px;
}

.sidebar .link {
  display: flex;
  margin-bottom: 25px;
  height: 64px;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
}

.sidebar .link svg {
  transition: 0.3s;
  fill: #8387a5;
}
.sidebar .link:hover svg {
  fill: #5f48e9;
}
.sidebar .linkActive svg {
  fill: #5f48e9;
}
.sidebar .linkActiveBefore {
  position: absolute;
  left: 0;
  top: 0;
  height: 64px;
}
.userPictureWrapper {
  margin: 0 0 0 24px;
  border: 0;
  padding: 0;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  -moz-appearance: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}
.userPicture {
  border-radius: 50%;
}
.link {
  cursor: pointer;
}
.headerDivider {
  margin: 0;
  height: 100%;
  width: 1px;
  border: none;
  background-color: #eff3f7;
}
.headerActions {
  background-color: transparent;
  border: none;
  width: 48px;
  height: 48px;
  padding: 0;
}
.headerActions:hover svg path {
  fill: #5f48e9;
}

.actionNotification {
  position: relative;
  margin: 0 20px;
}
.actionNotification::after {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ff336a;
  content: '';
  top: 9px;
  right: 12px;
}
.linkActiveOpen::after {
  content: '';
  width: 100%;
  height: 64px;
  position: absolute;
}
.link {
  background-color: transparent;
  transition: background-color 0.2s;
}
.link:hover {
  background-color: #f2f2ff;
}
.linkActiveOpen {
  background-color: #f2f2ff;
}
.version {
  display: block;
  margin: auto auto 0;
  transition: 0.2s;
  opacity: 1;
}
.versionClosed {
  opacity: 0;
}

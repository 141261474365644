.wrapper :global(.react-datepicker__header) {
  padding-right: 15px;
}

.wrapper :global(.react-datepicker__month) {
  padding-right: 15px;
}

.wrapper :global(.react-datepicker__time-container) {
  border-color: #f2f2f2;
}

.wrapper :global(.react-datepicker__time-list-item--selected) {
  background-color: var(--primary-button-color) !important;
}

.InputBase {
  font-size: 15px;
  min-height: 40px;
  padding: 4px 16px;
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  border-color: var(--primary-border-blur-color);
  align-items: center;
}

.InputBase:global(.Mui-focused) {
  border-color: var(--primary-text-link-color);
}

.InputBase:global(.Mui-error) {
  border-color: var(--primary-text-error-color);
}

.InputBase:global(.MuiInputBase-multiline) {
  padding-top: 4px;
  padding-bottom: 4px;
}

.InputBase :global(.MuiInputBase-input) {
  padding-top: 6px;
  padding-bottom: 6px;
}

.NameWrapper {
  display: flex;
  align-items: center;
}

.Name,
.JobTitle {
  span {
    font-weight: bold;
    color: #001847;
  }
}

.Name {
  font-size: 13px;
}

.JobTitle {
  font-size: 11px;
  color: #8387a5;
}

.Item {
  all: unset;
  width: 100%;
  margin-top: 10px;
  display: flex;
  cursor: pointer;
  height: 36px;
  align-items: center;

  &:first-child {
    margin-top: 0;
  }
}

.TextWrapper {
  margin-left: 10px;
}

.Avatar,
.Avatar img {
  height: 30px !important;
  width: 30px !important;
  background-color: #5f48e9;
}

.Tag {
  font-size: 10px !important;
  padding: 0 8px !important;
  margin-left: 10px;
}

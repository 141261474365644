:root {
  --primary-text-color: #001847;
  --primary-border-color: #eff3f7;
  --primary-button-color: #5f48e9;
  --primary-text-link-color: #5f48e9;
  --primary-border-focus-color: #5f48e9;
  --secondary-text-color: #8387a5;
  --secondary-button-color: #ffffff;
  --primary-text-error-color: #ff336a;
  --scondary-text-error-color: #ffe0e3;
  --primary-border-blur-color: #ced2db;
  --success-color: #0dba49;

  --color-black: #000000;
  --color-black-a20: rgba(0, 0, 0, 0.2);
  --color-raven: #727f8a;
  --color-mystic: #d8dfea;
  --color-white: #ffffff;
  --color-manatee: #8387a5;
  --color-manatee-a23: rgba(131, 135, 165, 0.23);
  --color-manatee-a10: rgba(131, 135, 165, 0.1);
  --color-whisper: #f5f6fa;
  --color-torch-red: #fa0043;
  --color-vaterloo: #757295;
  --color-vaterloo-a7: rgba(117, 114, 149, 0.07);
  --color-selago: #efecfd;
  --color-malachite: #19e35f;
  --color-perfume: #ab9ef7;
  --color-blue-gem: #2810b4;
  --color-mirage: #1b2638;
  --color-titan-white: #f2f2ff;

  --font-poppins: Poppins, sans-serif;

  --sidebar-width: 72px;
  --sidebar-width-expanded: 240px;

  --box-shadow-primary: 0 0 10px 0 var(--color-mystic);
}

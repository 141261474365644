.drawerContent {
  flex-grow: 1;
}

.DialogTitle {
  color: var(--primary-text-color);
}

.CancelButton {
  color: var(--color-manatee) !important;
}

.DiscardButton {
  color: var(--color-torch-red) !important;
}

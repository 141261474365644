.PreviewOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--color-mirage);
  z-index: 5001;
}

.Header {
  padding: 19px 38px 29px 38px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.IconsWrapper {
  display: flex;
  align-items: center;

  svg {
    cursor: pointer;
  }
}

.Progress {
  circle {
    stroke: var(--color-white) !important;
  }
}

.CloseIcon {
  all: unset;
  width: 25px;
  height: 25px;
  position: relative;
  cursor: pointer;
  margin-left: 50px;

  &:before,
  &:after {
    content: '';
    background-color: var(--color-white);
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1px;
    height: 100%;
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
}

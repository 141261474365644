.BaseButton {
  font-size: 15px;
  font-weight: 500;
  border-radius: 20px;
  padding: 7px 50px;
  text-transform: none;
}

.BaseButton.smallText {
  font-size: 13px;
  font-weight: 400;
}

.BaseButton.primary:global(.MuiButton-contained) {
  color: var(--secondary-button-color);
  background-color: var(--primary-button-color);
  box-shadow: none;
}

.BaseButton.primary:global(.MuiButton-contained):focus {
  background-color: var(--primary-button-color);
}

.BaseButton.primary:global(.MuiButton-contained):hover {
  background-color: #ab9ef7;
}

.BaseButton.primary:global(.MuiButton-contained):disabled {
  color: #f7f8fb;
  background-color: #d7d5f9;
}

.BaseButton:global(.MuiButton-text) {
  color: var(--secondary-text-color);
  padding-left: 10px;
  padding-right: 10px;
}

.BaseButton.primary:global(.MuiButton-text):not(:global(.MuiButton-root.Mui-disabled)) {
  color: var(--primary-button-color);
}

.BaseButton.error:global(.MuiButton-text):not(:global(.MuiButton-root.Mui-disabled)) {
  color: var(--primary-text-error-color);
}

.wrapper :global(.react-datepicker) {
  border: none;
}
.wrapper :global(.react-datepicker__header) {
  background: #fff;
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 10px;
}
.containerShow + :global(.MuiButtonBase-root) {
  display: none;
}
.wrapper :global(.react-datepicker__day-name) {
  color: #8387a5;
  font-size: 16px;
  width: 34px;
  margin-right: 20px;
}
.wrapper :global(.react-datepicker__day-name):last-child {
  margin-right: 0;
}
.wrapper :global(.react-datepicker__day) {
  width: 34px;
  margin: 0 20px 0 0;
  font-size: 17px;
  line-height: 2;
  color: var(--primary-text-color);
  height: 34px;
}
.wrapper :global(.react-datepicker__day):nth-child(7n) {
  margin-right: 0;
}
.wrapper :global(.react-datepicker__week) {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}
.wrapper :global(.react-datepicker__day--outside-month) {
  color: #d8dfea;
}
.wrapper :global(.react-datepicker__day--selected),
.wrapper :global(.react-datepicker__day--keyboard-selected),
.wrapper :global(.react-datepicker__month-text--keyboard-selected),
.wrapper :global(.react-datepicker__quarter-text--keyboard-selected) {
  color: #fff;
  background-color: #5f48e9;
  border-radius: 50%;
}
.wrapper :global(.react-datepicker__month) {
  margin: 10px 0 0;
}

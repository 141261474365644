.chip {
  color: var(--primary-text-color);
  background-color: rgba(131, 135, 165, 0.1);
  padding: 0 0 0 4px;
  font-size: 11px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  cursor: default;
  border: 0;
  position: absolute;
  max-width: calc(100% - 32px);
}

.Avatar {
  margin-right: 5px;
}

.text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.closeButton {
  display: block;
  background-color: transparent;
  border: 0;
  padding: 8px;
  margin: 0 0 0 6px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.closeButton:not(:disabled) {
  cursor: pointer;
}

.closeButton svg {
  width: 8px;
  height: 8px;
}

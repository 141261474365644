.Wrapper {
  height: calc(100vh - 192px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Message {
  color: var(--color-white);
  font-size: 19px;
  font-family: var(--font-poppins-medium);
  margin-top: 15px;
}

.Button {
  all: unset;
  display: flex;
  align-items: center;
  color: var(--color-white);
  font-family: var(--font-poppins-regular);
  font-size: 17px;
  margin-top: 50px;
  cursor: pointer;

  &:disabled {
    cursor: default;
  }
}

.ButtonText {
  margin-left: 15px;
}

.subTitle {
  border-radius: 11px;
  background-color: #f4f5f7;
  display: inline-flex;
  width: 100%;
  min-height: 50px;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 15px 25px;
  font-size: 16px;
}
.rowContainer {
  margin-bottom: 30px;
}
.confirmUploadButton {
  margin-left: auto;
  margin-bottom: 30px;
}
.container {
  display: flex;
  flex-flow: column;
}

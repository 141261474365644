@import 'src/styles/media';

.Header {
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eff3f7;
  padding: 0 30px 0 20px;

  @include media-sm {
    position: relative;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    padding: 0 20px;
  }

  &.WithSidebar {
    width: calc(100% - var(--sidebar-width));
    margin-left: var(--sidebar-width);
  }

  &.WithOpenedSidebar {
    width: calc(100% - var(--sidebar-width-expanded));
    margin-left: var(--sidebar-width-expanded);
  }

  &.WithOpenedSidebar,
  &.WithSidebar {
    // left sidebar isn't shown on mobile web
    @include media-sm {
      width: 100%;
      margin-left: 0;
    }
  }
}

.MobileLogo {
  svg {
    display: none;
    zoom: 0.8; // TODO: proper svg icon should use. It's temporary solution.

    @include media-sm {
      display: block;
    }
  }
}

.PageName {
  font-family: Poppins, sans-serif;
  font-size: 22px;
  color: #001847;
  font-weight: 600;
  display: block;

  @include media-sm {
    display: none;
  }
}

.Controls {
  display: flex;
  align-items: center;
  height: 100%;
}

.LeftControls {
  display: flex;
  align-items: center;

  @include media-sm {
    display: none;
  }
}

.Icon {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
}

.divider {
  height: 66px;
  width: 1px;
  background-color: #eff3f7;
  margin: 0 30px 0 42px;
  @include media-lg {
    margin: 0 30px 0 0;
    visibility: hidden;
  }
}
.notificationIconWithDot {
  position: relative;
  span.count {
    color: #fff;
    padding: 1px 5px 0 6px;
    border-radius: 9px;
    top: 6px;
    right: 6px;
    background-color: #fd336a;
    font-size: 11px;
    position: absolute;
  }
}
.Divider {
  margin: 18px 30px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.createdBy {
  align-self: center;
  margin-top: auto;
}

.Wrapper {
  margin-top: 30px;
}

.List {
  margin-top: 8px;
}

.ItemWrapper {
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 21px;
  margin-top: 10px;

  &:first-child {
    margin-top: 0;
  }
}

.Item {
  padding: 2px 12px;
  text-align: center;
  width: fit-content;
  font-size: 11px;
  font-weight: 500;
  border-radius: 4px;
  user-select: none;

  * {
    user-select: none;
  }
}

.Dialog {
  box-shadow: 10px 10px 40px 0 #d8dfea !important;
  border-radius: 12px !important;
  padding: 11px 8px !important;
  background: white !important;
  width: 202px !important;
}

.Input {
  background: rgba(117, 114, 149, 0.07);
  height: 36px;
  max-height: 36px;
  min-height: 36px !important;
  font-size: 13px !important;
}

.Divider {
  position: relative;
  width: calc(100% + 16px);
  left: -8px;
  height: 1px;
  background-color: #eff3f7;
  margin-top: 10px;
}

.StatusItem {
  &:first-child {
    margin-top: 10px;
  }
}

@import 'src/styles/media';

.container {
  width: 390px;
  border-radius: 20px;
  box-shadow: 10px 10px 40px 0 rgba(166, 158, 204, 0.1);
  text-align: center;

  @include media-sm {
    margin: 20px;
  }
}

.actions {
  justify-content: center;
}

.title {
  font-size: 19px;
  font-weight: 500;
  color: var(--primary-button-color);
  padding-top: 20px;
  padding-bottom: 5px;

  @include media-sm {
    h2 {
      font-size: 17px;
    }
  }
}

.description {
  color: var(--primary-text-color);
  font-size: 16px;
  text-align: center;

  @include media-sm {
    font-size: 14px;
  }
}

.button {
  text-transform: capitalize;
  font-size: 17px;
  font-weight: 400;
  margin-left: 0 !important;
  margin-right: 0 !important;

  &.Left {
    color: #8387a5 !important;
  }

  @include media-sm {
    font-size: 14px;
  }
}

.divider {
  height: 28px;
  margin: 0 20px !important;
}

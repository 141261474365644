.FormControl {
  margin-top: 0;
  margin-bottom: 0;
}

.FormControl:global(.MuiFormControl-marginNormal) {
  margin-top: 0;
  margin-bottom: 30px;
}

.FormControl:global(.MuiFormControl-marginDense) {
  margin-top: 0;
  margin-bottom: 10px;
}

.Wrapper {
  margin-top: 20px;
}

.List {
  margin-top: 15px;
  overflow: hidden;

  &.Expanded {
    max-height: unset !important;
  }
}

@import 'src/styles/media';

.Drawer {
  background: #001847;
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  overflow: hidden;
  height: 100%;

  @include media-sm {
    display: none;
  }
}

.DrawerClosed {
  width: 72px;
}

.DrawerOpened {
  width: 240px;
}

.Toolbar {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 240px;
  padding: 0;
  position: relative; // for plans promo
}

.LogoSection {
  all: unset;
  width: 100%;
  height: 68px;
  cursor: pointer;
  padding-left: 27px;
}

.ButtonsWrapper {
  margin-top: 21px;

  &.SidebarClosed {
    display: flex;
    flex-direction: column;
    width: 220px;
  }
}

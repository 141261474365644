@import '../../../styles/media';

.paddingWrapper {
  padding: 24px 36px;

  @include media-sm {
    padding: 20px;
  }
}

.header {
  position: sticky;
  top: 0;
  left: auto;
  right: 0;
  display: flex;
  min-height: 64px;
  background-color: #f5f6fa;
  align-items: center;
  justify-content: space-between;
  composes: paddingWrapper;
  padding-top: 0;
  padding-bottom: 0;
  z-index: 99;

  h6 {
    font-size: 20px;
    color: var(--primary-text-color);
    font-weight: 500;
    margin: 0;

    @include media-sm {
      font-size: 16px;
    }
  }

  .exitIcon {
    margin-right: -12px;
  }
}

.AppBar {
  background-color: #f5f6fa;
}

.content {
  composes: paddingWrapper;
}

.Title {
  color: var(--primary-text-color);
}

.Wrapper {
  position: relative;
}

.Divider {
  position: relative;
  width: calc(100% + 16px);
  left: -8px;
  height: 1px;
  background-color: #eff3f7;
  margin-top: 10px;
}

.Item {
  all: unset;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  height: 24px;
  margin-top: 10px;
  width: 100%;

  &:first-child {
    margin-top: 10px;
  }

  * {
    user-select: none;
  }

  &.Small {
    justify-content: space-between;
  }
}

.Small {
  .Tag {
    height: 20px;
  }
}

.Tag {
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  color: white;
  border-radius: 16px;
  white-space: nowrap;
  padding: 4px 16px;
}

.Dialog {
  box-shadow: 10px 10px 40px 0 #d8dfea !important;
  border-radius: 12px !important;
  padding: 11px 8px !important;
  background: white !important;
  width: 202px !important;
}

.Input {
  background: rgba(117, 114, 149, 0.07);
  font-size: 11px;
  height: 36px;
  max-height: 36px;
  min-height: 36px !important;
  font-size: 13px !important;
  border-color: transparent !important;
  width: 100%;

  &.Mui-focused {
    border-color: var(--primary-text-link-color) !important;
  }
}

.FilterTag {
  flex-direction: row-reverse;
}

.rowWrapper {
  margin-top: 30px;
  .rowTitle {
    font-size: 13px;
    color: #8387a5;
    text-transform: capitalize;
  }
  .rowContainer {
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    .errorLabel {
      position: absolute;
      color: #fa0043;
      font-size: 13px;
      right: 0;
      bottom: -20px;
    }
  }
  .exampleValue {
    border-radius: 6px;
    background-color: #eff3f7;
    font-size: 15px;
    color: #8387a5;
    border: none;
    max-width: 240px;
    width: 100%;
  }
  .select {
    max-width: 240px;
    width: 100%;
    height: 40px;
    padding: 0 20px 0 13px;
    font-size: 13px;
    color: #001847;
    .selectArrow {
      width: auto;
    }
    .selectMenu {
      font-size: 13px;
      color: #001847;
    }
    .placeholder {
      color: #5f48e9;
    }
  }
}
.selectItem {
  font-size: 11px;
}
.selectMenuList {
  font-size: 11px;
}

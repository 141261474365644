.SidebarLink {
  height: 48px;
  width: 220px;
  transition: 150ms ease;
  cursor: pointer;
  margin-top: 20px;
  display: flex;
  align-items: center;
  position: relative;
  // transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;

  &:first-child {
    margin-top: 0;
  }

  &.Active,
  &:hover {
    &::after {
      opacity: 1;
    }
  }

  &.Active {
    span {
      font-weight: 400;
    }
  }

  &.SidebarOpened {
    width: 220px;
  }

  &.SidebarClosed {
    width: 48px;

    span {
      display: none;
    }

    &:after {
      width: 48px;
    }
  }

  svg,
  span {
    z-index: 1;
  }

  span {
    display: block;
    margin-left: 15px;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    color: #fff;
    font-size: 13px;
    font-weight: 300;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: #65697c;
    width: 100%;
    height: 100%;
    z-index: 0;
    border-radius: 6px;
    opacity: 0;
    transition: opacity 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  }
}

.TooltipMask {
  width: 48px;
  height: 48px;
  z-index: 1;
}

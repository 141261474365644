.Popper {
  z-index: 7777;
  margin-top: 10px;
  max-width: 350px;
  width: 100%;
}

.Grow {
  transform-origin: 0 0 0;
}

.contentWrapper {
  width: 100%;
}

.content {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 10px 10px 40px 0 #d8dfea;
  overflow: hidden;
}

.user {
  padding: 16px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-bottom: 1px solid rgb(216, 223, 234, 0.5);
}

.userPic {
  margin-bottom: 6px;
}

.name {
  font-size: 16px;
  line-height: 25px;
  color: #000;
}

.email {
  font-size: 13px;
  color: #727f8a;
  margin-bottom: 8px;
}

.name,
.email {
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.logoutButton {
  border: 0;
  padding: 0 20px;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  -moz-appearance: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  font-family: inherit;
  font-size: 12;
  color: #727f8a;
  border-color: rgb(216, 223, 234, 0.5);
  border-style: solid;
  border-width: 1px;
  border-radius: 18px;
  transition: 0.2s color, 0.2s border-color;
}

.logoutButton:hover {
  color: var(--primary-button-color);
  border-color: var(--primary-button-color);
}

.workspacesSelectWrapper {
  max-height: 450px;
  overflow: auto;
}

.workspaces {
  padding: 14px 18px 24px;
}

.workspaces .title {
  font-size: 13px;
  line-height: 1.85;
  color: var(--secondary-text-color);
  text-transform: capitalize;
}

.workspacesSelect {
  margin-top: 4px;
}

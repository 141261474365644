.Item {
  padding: 15px;
  max-width: 100%;
  overflow: hidden;
  font-family: var(--font-poppins-regular);
  color: var(--primary-text-color);
  font-size: 13px;
  line-height: 15px;
  min-height: 50px;
  max-height: 50px;
}

.Body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.IconFileName {
  display: flex;
  align-items: center;

  img {
    user-select: none;
  }
}

.FileName {
  margin-left: 10px;
}

.ProgressWrapper {
  position: relative;
  width: 18px;
  height: 18px;

  .Progress,
  .ProgressBackground {
    position: absolute;
    top: 0;
    left: 0;
  }

  .Progress {
    z-index: 1;
  }
}

.IconLoaded {
  width: 20px;
  height: 20px;
  background-color: var(--color-malachite);
  border-radius: 50%;

  svg {
    transform: scale(0.7);
    stroke: var(--color-white) !important;
  }
}

.Message {
  font-family: var(--font-poppins-regular);
  font-size: 11px;
  line-height: 15px;
  color: var(--color-manatee);
  padding-left: 22px;
  margin-top: 3px;
}

.RetryIcon.RetryIcon {
  cursor: pointer !important;
}

.ErrorIcon {
  height: 20px;
  width: 20px;
  background-color: var(--color-torch-red);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-white);
  font-size: 15px;
  margin-left: 10px;
}

.CancelIcon {
  width: 20px;
  height: 20px;
  background: var(--primary-text-color);
  border-radius: 50%;
  position: relative;

  svg {
    max-width: 8px;
    max-height: 8px;
    fill: var(--color-white);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.ProgressCancelWrapper {
  width: 20px;
  height: 20px;

  .CancelIcon {
    display: none;
  }

  &:hover {
    .ProgressWrapper {
      display: none;
    }

    .CancelIcon {
      display: block;
    }
  }
}

.IconsWrapper {
  display: flex;
  align-items: center;
}

.imageWrapper {
  border-radius: 50%;
}

.imageWrapper img {
  border-radius: 50%;
  object-fit: cover;
  object-position: center top;
}

.placeholder {
  border-radius: 50%;
  background-color: var(--primary-text-color);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  color: #fff;
  text-align: center;
}

.placeholderDefault svg {
  fill: #fff;
  width: 50%;
  height: 50%;
  min-width: 18px;
  min-height: 18px;
}

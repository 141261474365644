.Label {
  font-size: 15px;
  line-height: 24px;
  font-weight: 400;
  color: var(--primary-text-color);
  transform: none;
  position: relative;
  text-align: left;
  margin-bottom: 7px;
}

.InfoIcon {
  width: 24px;
  height: 24px;
  vertical-align: bottom;
  margin-left: 6px;
}

.hint {
  display: inline-block;
}

.Tooltip:global(.MuiTooltip-tooltip) {
  background-color: var(--primary-text-color);
  font-size: 13px;
  line-height: 1.54;
  font-weight: 400;
  color: #fff;
  padding: 8px;
}

.Tooltip :global(.MuiTooltip-arrow) {
  color: var(--primary-text-color);
}

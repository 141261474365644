.wrapper {
  position: absolute;
  bottom: -150px;
  left: 0;
  transform-origin: top left;
  transform: rotateZ(-90deg) translateX(-100%);
  background-color: #ffa514;
  color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  font-size: 14px;
  border-radius: 0 0 10px 10px;
  padding: 5px 15px;
  user-select: none;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    background-color: #f5b958;
  }

  svg {
    margin-right: 10px;
  }
}

.container {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  max-width: 235px;
  width: 100%;
  margin: 0 auto;
  color: var(--primary-button-color);
  height: 100%;
  .title {
    letter-spacing: -0.46px;
    font-size: 19px;
    color: var(--primary-text-color);
    font-weight: 500;
    margin-top: 30px;
    margin-bottom: 10px;
  }
  .description {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 17px;
    color: var(--primary-text-color);
    font-weight: 400;
    text-align: center;
  }
}

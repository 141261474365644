.Info {
  display: flex;
  align-items: center;
  width: calc(100% - 30px);

  &.LightMode {
    * {
      color: var(--color-white);
    }
  }
}

.IconWrapper {
  min-width: 27px;
  min-height: 36px;
}

.InfoText {
  margin-left: 18px;
  width: calc(100% - 70px);

  span {
    color: var(--color-manatee);
    font-size: 13px;
    line-height: 20px;
  }
}

.Name {
  color: var(--primary-text-color);
  font-size: 15px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  font-family: var(--font-poppins-regular);

  span {
    font-size: 15px;
    font-family: var(--font-poppins-medium);
    color: var(--primary-text-color);
  }

  &.FullName {
    max-width: calc(100vw - 242px) !important;
  }

  &.FullNameSearch {
    max-width: calc(100% - 45px) !important;
  }
}

.Thumbnail {
  width: 27px;
  height: 36px;
  background-position: center;
  background-size: cover;
  cursor: pointer;
  border-radius: 5px;
}

.Icon {
  cursor: pointer;
}

@import 'src/styles/media';

.container {
  width: 100%;
  max-width: 722px;

  @include media-sm {
    margin: 20px;
  }
}

.wrapper {
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  @include media-sm {
    padding: 20px;
  }

  h1 {
    font-size: 36px;
    margin: 0;
    font-weight: 600;
    color: var(--primary-text-color);

    @include media-sm {
      font-size: 26px;
    }
  }

  h2 {
    font-size: 30px;
    margin: 0;
    font-weight: 400;
    color: var(--primary-text-color);

    @include media-sm {
      font-size: 18px;
    }
  }

  p {
    font-size: 16px;
    margin: 20px 0 30px;
    color: var(--primary-text-color);
    text-align: center;
    max-width: 400px;

    @include media-sm {
      font-size: 14px;
    }

    a {
      color: var(--primary-text-link-color);
    }

    span {
      display: block;
      margin-top: 14px;
      font-size: 12px;
      color: var(--secondary-text-color);
    }
  }

  svg {
    margin-bottom: 20px;
  }

  button {
    min-height: 50px;
    min-width: 200px;
    margin-bottom: 20px;
    text-transform: uppercase;

    @include media-sm {
      min-height: 40px;
      min-width: 180px;
    }
  }
}

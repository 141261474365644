.Dialog {
  padding: 15px 21px 29px;
  border-radius: 12px;
  box-shadow: var(--box-shadow-primary);
  width: 390px;
}

.Title {
  font-size: 19px;
  font-family: var(--font-poppins-regular);
  color: var(--primary-text-color);
  line-height: 38px;
}

.Input {
  all: unset;
  border-radius: 6px;
  border: 1px solid var(--primary-border-color);
  transition: 200ms;
  color: var(--primary-text-color);
  font-size: 13px;
  padding: 6px 11px;
  margin-top: 17px;

  &:focus {
    border-color: var(--primary-border-focus-color);
  }
}

.Buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 26px;
}

.Button {
  border-radius: 20px;
  padding: 6px 33px;
  font-size: 13px;
  color: var(--color-white);
  background-color: var(--primary-button-color);
  margin-right: 15px;

  &:hover {
    background-color: var(--color-perfume);
  }

  &:last-child {
    margin-right: 0;
  }
}

.ButtonCancel {
  background-color: var(--color-white);
  color: var(--color-black-a20);

  &:hover {
    background-color: var(--color-manatee-a10);
  }
}

.Tag {
  padding: 5px 10px 4px 11px;
  border-radius: 4px;
  background-color: #f2f3f6;
  font-size: 10px;
  font-weight: 600;
  width: fit-content;
  text-transform: uppercase;
  user-select: none;
}

.ListWrapper {
  margin-top: 15px;
  max-height: 128px;
  overflow: hidden;

  &.Expanded {
    overflow: unset;
    max-height: unset;
  }
}

.SeeAll {
  margin: 12px 0 0;
  font-size: 13px;
  color: #5f48e9;
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    fill: #5f48e9;
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }
}

.Divider {
  width: 100%;
  height: 1px;
  background: #d1d9e6;
  opacity: 0.5;
  margin-top: 12px;
}

.wrapper {
  text-align: right;
  font-size: 11px;
  text-transform: uppercase;
  color: var(--primary-text-color);

  a {
    text-decoration: underline;
    color: var(--primary-text-link-color);
    font-weight: 500;

    &:hover {
      text-decoration: none;
    }
  }
}

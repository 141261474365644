@import 'src/styles/media';

.wrapper {
  text-align: center;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  flex: 1;

  @include media-sm {
    height: calc(100vh - 68px);
    min-height: 0;
    overflow: hidden;
    background-color: var(--color-white);
  }
}

.layoutIcons {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;

  @include media-sm {
    display: none;
  }
}

.layoutIcons svg {
  position: absolute;
}

.layoutIcons .circlesTopLeft {
  left: 18%;
  top: 37px;
}

.layoutIcons .circlesTopRight {
  right: 14.5%;
}
.layoutIcons .circlesBottomLeft {
  left: 31%;
  bottom: 6%;
}
.layoutIcons .dashesBottomRight {
  right: 17%;
  bottom: 0;
}

.Tooltip {
  background: #001847;
  font-size: 13px;
  padding: 11px;
  font-weight: 200;
  box-shadow: 10px 10px 40px 0 #d8dfea;

  span::before {
    background: #001847;
  }
}

.wrapper {
  min-width: 640px;
}

.wrapper input::-webkit-contacts-auto-fill-button,
.wrapper input::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  position: absolute;
  right: 0;
}

.navigationButton {
  font-family: inherit;
  font-size: 13px;
  color: var(--primary-text-color);
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  transition: 0.2s color;
}

.navigationButton:hover {
  color: var(--primary-button-color);
}

.settingsButton {
  text-decoration: underline;
}

.mainSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.form {
  width: 100%;
}

.mainSectionFormWrapper {
  margin-top: 40px;
  width: 100%;
}

.settingsSection {
  width: 100%;
}

.settingsSectionFormWrapper {
  margin-top: 30px;
  width: 100%;
}

.backButton svg {
  width: 15px;
  height: 15px;
  margin-right: 6px;
}

.settingsForm {
  position: relative;
}

.settingsForm .noPermission {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 1;
}

.settingsForm .noPermissionText {
  max-width: 520px;
  margin: auto;
  padding: 25px;
  border-radius: 13px;
  box-shadow: 10px 10px 40px 0 #d8dfea;
  background-color: #fff;
  font-size: 15px;
  text-align: center;
}

.settingsForm .noPermissionText span {
  color: var(--primary-text-error-color);
}

.logoWrapper {
  padding: 0 0 10px;
  display: flex;
  justify-content: center;
}

.ItemsFieldList {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.ItemsFieldList .ItemsFieldItem:not(:last-child) {
  margin-bottom: 30px;
}

.ItemsFieldList .indexItem {
  margin-right: 4px;
  opacity: 0.5;
}

.ItemsFieldList .currencySymbol {
  margin-right: 5px;
  color: var(--secondary-text-color);
}

.bottom {
  min-height: 40px;
}

.Spinner {
  margin-right: 15px;
}

.PreviewSpinner {
  display: block;
  margin-left: 60px;
}

.SendButton {
  margin-left: 40px;
}

.DownloadButton {
  margin-left: 40px;
  padding-left: 32px;
  padding-right: 32px;
}

.saveButtonContainer {
  position: sticky;
  bottom: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  height: 70px;
  justify-content: flex-end;
}
.saveButtonContainer::after {
  content: '';
  width: calc(100% + 72px);
  top: 0;
  height: 100%;
  left: -36px;
  z-index: 0;
  position: absolute;
  background-color: #fff;
  box-shadow: 0 -3px 10px 0 rgba(0, 0, 0, 0.1);
}

.saveButtonContainer > * {
  z-index: 1;
}

.priceSummary {
  padding: 25px 0;
  border-top: 1px solid #aab5c8;
  border-bottom: 1px solid #aab5c8;
  margin-bottom: 25px;

  h3 {
    font-size: 17px;
    margin: 0 0 25px;
    font-weight: normal;
  }
}

.priceSummaryRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  min-height: 30px;

  &:last-child {
    margin-bottom: 0;
  }

  h4 {
    font-weight: normal;
    margin: 0;
    font-size: 15px;
  }

  b {
    font-weight: 500;
  }
}

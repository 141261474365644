.Wrapper {
  margin-top: 20px;
}

.List {
  margin-top: 15px;
}

.Item {
  all: unset;
  width: 100%;
  cursor: pointer;
  margin-top: 15px;
}

.ItemInner {
  display: flex;
  align-items: center;
}

.Icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #5f48e9;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.Info {
  margin-left: 11px;
  display: flex;
  width: 100%;
}

.InfoText {
  span {
    font-size: 13px;

    span {
      font-weight: bold;
      color: #001847 !important;

      &:nth-child(2) {
        margin-left: unset;
      }
    }

    &:first-child {
      color: #8387a5;
    }

    &:nth-child(2) {
      margin-left: 14px;
      color: #001847;
    }
  }
}

.AmountInfo {
  margin-left: auto;

  div {
    background: #f2f3f6;
    font-size: 11px;
    color: #001847;
    padding: 2px 10px;
    border-radius: 4px;
    text-transform: uppercase;
    font-weight: 500;
    user-select: none;
  }

  span {
    font-size: 11px;
    color: #8387a5;

    span {
      font-weight: 500;
      color: #001847;
    }
  }
}

.ContactInfo {
  display: flex;
  align-items: center;
  margin-left: 41px;

  span {
    font-size: 11px;
    color: #8387a5;
    margin-left: 6px;

    span {
      font-weight: bold;
      color: #001847;
    }
  }
}

.Avatar {
  width: 20px !important;
  height: 20px !important;
  font-size: 12px !important;

  img {
    width: 20px;
    height: 20px;
  }
}

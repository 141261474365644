.container {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  border-radius: 17px;
  box-shadow: 10px 10px 40px 0 rgba(0, 0, 0, 0.18);
  background-color: #fff;
  max-width: 100%;
}
.contentWrapper {
  padding: 0 55px;
}
.description {
  font-size: 16px;
  margin-bottom: 40px;
  color: #001847;
}
.title {
  margin-top: 45px;
  margin-bottom: 5px;
  padding: 0;
}
.title h2 {
  font-weight: 600;
  font-size: 30px;
  color: #5f48e9;
}
.videoWrapper {
  width: 530px;
  margin-bottom: 40px;
  padding: 56.25% 0 0 0;
  position: relative;
  & > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.closeButton {
  padding: 12px;
  position: absolute;
  top: 20px;
  right: 15px;
  svg {
    width: 16px;
    height: 16px;
    path {
      fill: #8387a5;
    }
  }
}

@import 'src/styles/media';
.Wrapper {
  display: flex;
  align-items: center;
  margin: 0 35px 0 60px;
  cursor: pointer;
  @include media-lg {
    margin: 0 15px 0 40px;
  }
  img {
    width: 24px;
    height: 24px;
  }
}

.LanguageName {
  font-family: var(--font-poppins);
  margin-left: 8px;
  color: var(--color-vaterloo);
  font-size: 11px;
  position: relative;
}

.Arrow {
  width: 7px;
  height: 7px;
  border: 1px solid var(--color-vaterloo);
  border-top: none;
  border-left: none;
  transform: rotate(45deg);
  transform-origin: center;
  margin-left: 7px;
  margin-top: -4px;
}

.Modal {
  width: 250px;
  border-radius: 12px;
  box-shadow: 10px 10px 40px 0 var(--color-mystic);
  max-height: 397px;
  overflow-y: scroll;
  padding-bottom: 4px;
}

.ModalHeader {
  padding: 10px;

  input {
    width: 100%;
    border-radius: 5px;
    border: none;
    background: var(--color-vaterloo-a7);
    padding: 10px;
  }
}

.Item {
  display: flex;
  align-items: center;
  padding: 7px 14px;
  cursor: pointer;
  transition: background-color 200ms ease;

  &:hover {
    background-color: var(--color-selago);
  }

  img {
    width: 24px;
    height: 24px;
  }

  span {
    display: block;
    margin-left: 10px;
    font-family: var(--font-poppins);
    font-size: 11px;
    color: var(--primary-text-color);
  }
}

.Input {
  width: 100%;
  background-color: var(--color-vaterloo-a7);

  svg {
    width: 14px;
    height: 14px;
    margin-right: 10px;
  }

  input {
    background-color: unset;
    padding: 10px;
    color: var(--color-manatee);
    font-family: var(--font-poppins);
    font-size: 11px;
  }
}

.SnackbarContent {
  min-width: 344px;
}

.card {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  border-radius: 4px;
  padding: 8px 12px 8px 20px;
  box-shadow: 3px 3px 6px 0 rgb(56 56 56 / 5%);
  background-color: #d0f2e1;
  border-width: 1px;
  border-style: solid;
}

.card.success {
  background-color: #d0f2e1;
  border-color: #19e35f;
  color: var(--success-color);
  fill: var(--success-color);
}

.card.error {
  background-color: #ffe4e4;
  border-color: #ffcdcd;
  color: var(--primary-text-error-color);
  fill: var(--primary-text-error-color);
}

.iconContainer {
  margin-right: 10px;
}

.iconContainer svg {
  display: block;
  width: 16px;
  height: 16px;
}

.text {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.25;
}

.CloseButton {
  padding: 8px;
  margin-left: auto;
}

.CloseButton svg {
  width: 14px;
  height: 14px;
}

.CloseButton svg,
.iconContainer svg {
  color: inherit;
  fill: inherit;
}
